import { Typography, Box, TextField, Input } from "@mui/material";
import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import config from "../config";
import axios from "axios";
import { StarOutlined, StarOutlineOutlined } from "@mui/icons-material";
import Cookies from "js-cookie";
import { SendOutlined } from "@mui/icons-material";
import Loader from "../partials/loader";
import ErrorModal from "../partials/ErrorModal";

const container: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  marginBottom: "100px",
};

const ratingContainer: React.CSSProperties = {
  display: "flex",
  gap: "2px",
  justifyContent: "center",
  width: "80%",
  margin: "auto",
};

const commentBox: React.CSSProperties = {
  width: "80%",
  background: "#F9E4F8",
  padding: "12px",
  borderRadius: "12px",
  margin: "auto",
};

const commentContainer: React.CSSProperties = {
  position: "fixed",
  width: "100%",
  bottom: 0,
  left: 0,
  minHeight: "70px",
  backgroundColor: "#006A6A",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  color: "#fff",
  gap: "4px",
  padding: "0px 4px",
};
const commentTextArea: React.CSSProperties = {
  width: "85%",
  color: "#fff",
  backgroundColor: "#006A6A",
  resize: "none",
  borderRadius: "8px",
  borderColor: "#FFF",
  padding: " 8px",
};

interface RatingParameters {
  HwaffleID: number;
  waffle_rating: number;
}
interface CommentParameters {
  HwaffleID: number;
  waffle_rating_comment: string;
}

const RatingCard = (waff: any) => {
  const waffle = waff.waffle;
  const userId = Cookies.get("superWaffles");
  const commt = waffle.waffle_rating_comment
    ? waffle.waffle_rating_comment
    : "";
  const [rating, setRating] = useState(waffle.waffle_rating);
  const [hWaffleId, sethWaffleId] = useState(waffle.hwaffleid);
  const [comment, setComment] = useState(commt);
  const [inputValue, setInputValue] = useState("");
  const [isSendVisible, setIsSendVisible] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [isRatingMounted, setRatingMounted] = useState(false);
  const [isCommentMounted, setCommentMounted] = useState(false);

  useEffect(() => {
    const huserId = waffle.huserid.toString();
    huserId == userId ? setCanEdit(true) : setCanEdit(false);
  }, [waffle.huserid, userId]);

  //update waffle_rating
  useEffect(() => {
    if (isRatingMounted) {
      const params: RatingParameters = {
        HwaffleID: hWaffleId,
        waffle_rating: rating,
      };
      const apiUrl = config.apiUrl + "waffles/updateWaffle";
      setIsLoading(true);
      axios
        .post(apiUrl, params, {
          headers: {
            Authorization: `Bearer ${Cookies.get("captainWaffles")}`,
          },
        })
        .then((res) => {
          //console.log('success',res);
        })
        .catch((error) => {
          console.log("error", error);
          setError(
            "Opps! Looks like Tim messed up the backend again, please try again some other time."
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setRatingMounted(true);
    }
  }, [rating]);
  //update waffle_rating_comment
  useEffect(() => {
    if (isCommentMounted) {
      const params: CommentParameters = {
        HwaffleID: hWaffleId,
        waffle_rating_comment: comment,
      };
      const apiUrl = config.apiUrl + "waffles/updateWaffle";
      setIsLoading(true);
      axios
        .post(apiUrl, params, {
          headers: {
            Authorization: `Bearer ${Cookies.get("captainWaffles")}`,
          },
        })
        .then((res) => {
          // console.log('success',res);
        })
        .catch((error) => {
          console.log("error", error);
          setError(
            "Opps! Looks like Tim messed up the backend again, please try again some other time."
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setCommentMounted(true);
    }
  }, [comment]);

  //event listeners
  const handleStarClick = (selectedRating: number) => {
    if (canEdit) {
      setRating(selectedRating);
    }
  };
  const handleCommentAdded = (
    event: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    setComment(inputValue);
    setInputValue("");
  };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
    event.target.value.length > 0
      ? setIsSendVisible(true)
      : setIsSendVisible(false);
  };
  const closeModal = () => {
    setError(null);
  };
  return (
    <Box style={container}>
      <Box style={ratingContainer}>
        {[1, 2, 3, 4, 5].map((index) => (
          <span
            key={index}
            onClick={() => handleStarClick(index)}
            style={{ cursor: "pointer" }}
          >
            {index <= rating ? (
              <StarOutlined
                sx={{ color: "primary.dark", mr: 1, fontSize: "3.5rem" }}
              />
            ) : (
              <StarOutlineOutlined
                sx={{ color: "primary.dark", mr: 1, fontSize: "3.5rem" }}
              />
            )}
          </span>
        ))}
      </Box>

      {comment !== "" && (
        <Box style={commentBox}>
          <Box sx={{ color: "black" }}>
            <Typography variant="body2">{comment}</Typography>
          </Box>
        </Box>
      )}

      {canEdit && (
        <Box style={commentContainer}>
          {
            <textarea
              style={commentTextArea}
              className="commentBox"
              rows={2} // Initial number of rows
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Comment"
            />
          }
          {isSendVisible && (
            <SendOutlined
              onClick={(event) =>
                handleCommentAdded(
                  event as React.MouseEvent<SVGElement, MouseEvent>
                )
              }
              sx={{ color: "#fff", mr: 1 }}
            />
          )}
        </Box>
      )}
      {isLoading && <Loader loading={isLoading}></Loader>}
      {error && <ErrorModal error={error} onClose={closeModal} />}
    </Box>
  );
};
export default RatingCard;
