import { Backdrop, CircularProgress } from "@mui/material";

interface LoadingProps {
    loading: boolean,
    // onClose: () => void;
}

const Loader: React.FC<LoadingProps> = ({ loading }) => {


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={Boolean(loading)}
        // onClick={onClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )

}
export default Loader;