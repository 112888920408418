const NotFound = () => {

    return (
         <div>
            <h2>404</h2>
            <div>
              <p>This page doesnt exist</p>
            </div>
        </div>
    )
}

export default NotFound