import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from "../config";
import { Box, Typography } from '@mui/material';
import { ThumbUp, ThumbUpOutlined, ThumbDown, ThumbDownOutlined, CheckCircleOutline, CancelOutlined, } from '@mui/icons-material/';
import { StarOutlined, StarOutlineOutlined } from "@mui/icons-material";
import ErrorModal from '../partials/ErrorModal';

interface VoteBarProps {
    waffleID: number;
}

interface Params {
    HwaffleID: number;
    voteYesYN: number;
    voteNoYN: number;
    voterComments: string;
}

interface WaffleData {
    activeyn: string,
    datecreated: string,
    fname: string,
    huserid: number,
    hwaffleid: number,
    imagereference: string | null,
    sname: string,
    uservoteyn: number | null,
    votesno: number | null,
    votesyes: number | null,
    waffledescription: string | null,
    wafflename: string,
    wafflestatus: string,
}

const VoteBar: React.FC<VoteBarProps> = ({ waffleID }) => {
    const [waffleData, setWaffleData] = useState<WaffleData | undefined>();
    const [currVote, setCurrVote] = useState<string | null>(null);
    const [qualified, setQualified] = useState<boolean>(true);
    const [yesVotes, setYesVotes] = useState<number>(0);
    const [noVotes, setNoVotes] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const apiUrl = config.apiUrl + `waffles/${waffleID}`;
        axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${Cookies.get('captainWaffles')}`
            }
        })
            .then(res => {
                const waffle = res.data.data[0];
                setWaffleData(waffle);

                if (waffle) {
                    waffle.uservoteyn === 1 && setCurrVote('Yes');
                    waffle.uservoteyn === 0 && setCurrVote('No');
                    setYesVotes(waffle.votesyes || 0);
                    setNoVotes(waffle.votesno || 0);
                    waffle.wafflestatus === 'Rejected' ? setQualified(false) : setQualified(true);
                }
            })
            .catch(error => {
                console.error(error);
                setError('Failed to fetch waffle data.');
            });
    }, [waffleID]);

    const handleVoteClick = (vote: number, event: React.MouseEvent<SVGElement, MouseEvent>) => {
        event.stopPropagation();

        const params: Params = {
            HwaffleID: waffleID,
            voteYesYN: vote === 1 ? 1 : 0,
            voteNoYN: vote === 1 ? 0 : 1,
            voterComments: '',
        };


        const apiUrl = config.apiUrl + 'votes';
        axios
            .post(apiUrl, params, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('captainWaffles')}`,
                },
            })
            .then(res => {
                vote === 1 ? setCurrVote('Yes') : setCurrVote('No');
                const voteData = res.data.data;
                voteData.waffleStatus === 'Rejected' ? setQualified(false) : setQualified(true);
                setNoVotes(voteData.voteNoYN || 0);
                setYesVotes(voteData.voteYesYN || 0);

            })
            .catch(error => {
                setError(`You can't vote on your own waffle. Dickhead.`);
            });
    };

    const voteBox: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
    };

    const closeModal = () => {
        setError(null);
    };

    return (
        <Box style={voteBox}>
            {currVote === 'Yes' ? (
                <ThumbUp onClick={(event) => handleVoteClick(1, event as React.MouseEvent<SVGElement, MouseEvent>)} sx={{
                    color: 'primary.light',
                    mr: 1,
                }} />
            ) : (
                <ThumbUpOutlined onClick={(event) => handleVoteClick(1, event as React.MouseEvent<SVGElement, MouseEvent>)} sx={{
                    color: 'primary.light',
                    mr: 1,
                }} />
            )}
            <Typography variant='subtitle2' sx={{
                color: 'primary.light',
                mr: 1,
            }}>{yesVotes}</Typography>
            {currVote === 'No' ? (
                <ThumbDown onClick={(event) => handleVoteClick(0, event as React.MouseEvent<SVGElement, MouseEvent>)} sx={{
                    color: 'secondary.main',
                    mr: 1,
                }} />
            ) : (
                <ThumbDownOutlined onClick={(event) => handleVoteClick(0, event as React.MouseEvent<SVGElement, MouseEvent>)} sx={{
                    color: 'secondary.main',
                    mr: 1,
                }} />
            )}
            <Typography variant='subtitle2' sx={{
                color: 'secondary.main',
                mr: 2,
            }}>{noVotes}</Typography>
            {qualified ? <CheckCircleOutline sx={{
                color: 'success.light'
            }} /> : <CancelOutlined sx={{
                color: 'error.main'
            }} />}

            {error && <ErrorModal error={error} onClose={closeModal} />}
        </Box>
    );
};

export default VoteBar;
