import { Typography, Box, TextField, Input } from "@mui/material"
import { ThumbDownOutlined, ThumbUpOutlined } from "@mui/icons-material";


const likesBox: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    gap: '4px',
    background: '#F9E4F8',
    padding: '12px',
    borderRadius: '12px'
};


const LikesCard = (likesObj: any) => {
    const like = likesObj.like
    return (

        // {like.voteyn &&(
        <Box style={likesBox}>
            {
                like.voteyn ?
                    (<ThumbUpOutlined sx={{ color: 'primary.light', mr: 1, }} />) :
                    (<ThumbDownOutlined sx={{ color: 'secondary.dark', mr: 1, }} />)
            }
            {like.fname}
            {
                like.voteyn ? " voted yay." : " voted nay."
            }
        </Box>
        // )}

    )
}
export default LikesCard;