import { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import config from "../config";



const useGet = (url: string) => {
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const apiUrl = config.apiUrl + url;

    useEffect(() => {
        axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${Cookies.get('captainWaffles')}`
            },
        })
            .then(res => {
                setData(res.data.data);
            })
            .catch(error => {
                setError('Oops. Waffles are still cooking! Please try again later.');
            });
    }, [apiUrl]); // Include params in the dependency array

    return {
        data,
        error
    };
}

export default useGet;
