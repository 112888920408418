import React from 'react';
import { Modal, Typography, Button } from '@mui/material';

interface ErrorModalProps {
    error: string | null;
    onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ error, onClose }) => {
    const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <Modal open={Boolean(error)} onClose={onClose}>
            <div
                onClick={handleModalClick}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <div style={{ background: 'white', padding: '2rem' }}>
                    <Typography variant="h5" component="div">
                        Error
                    </Typography>
                    <Typography variant="body1" component="div">
                        {error}
                    </Typography>
                    <Button variant="contained" onClick={onClose} style={{ marginTop: '1rem' }}>
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ErrorModal;
