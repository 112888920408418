import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const Theme = createTheme({
 palette: {
    primary: {
      main: '#006A6A',
      light: '#009D96',
      dark: '#006A6A',
    },
    secondary: {
      main: '#EBA5DC',
      dark: '#ED68D2',
      light: '#F9E4F8',
    },
  },
  typography: {
    button: {
      lineHeight: 1.55,
      letterSpacing: '0.06em',
      fontWeight: 400,
      textTransform: 'none',
      fontSize: '1rem',
    },
    
  },
   shape: {
    borderRadius: 10,
  },
   

  
});

export default Theme;