import { Padding } from "@mui/icons-material";
import { Typography, TextField, Box, Tabs, Tab, Button } from "@mui/material";


const commentBox: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    background: '#F9E4F8',
    padding: '1rem',
    borderRadius: '12px',
};

const CommentCard = (commentObj: any) => {
    /*    datecreated: "xx",
      fname: "xx",
      history_comment: inputValue,
      huserid: 1,
      hwaffleid: waffleData?.hwaffleid,
      sname: "x",
      voteyn:0, */
    const comment = commentObj.comment
    return (
        <Box style={commentBox}>
            <Box sx={{ display: "flex", justifyContent: 'space-between', color: 'primary.dark' }}>
                <Typography variant="subtitle2" >{comment?.fname} {comment?.sname}</Typography>
                <Typography variant='subtitle2' >{comment && comment.datecreated}</Typography>
            </Box>
            <Box sx={{ color: 'black' }}>
                <Typography variant='body2'  >{comment && comment.history_comment}</Typography>
            </Box>
        </Box>
    )
}
export default CommentCard;