import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Camera: React.FC = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    const handleCameraClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const formData = new FormData();
            formData.append('photo', file);

            // Redirect to the form page and pass the captured photo in state
            navigate('/enterWaffle', { state: { photo: formData } });
        }
    };

    return (
        <div>
            <h1>Camera Page</h1>
            <button onClick={handleCameraClick}>Open Camera</button>
            <input
                ref={inputRef}
                type="file"
                accept="image/*"
                capture={true}
                onChange={handleCapture}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default Camera;
