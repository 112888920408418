import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography, TextField, Box } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../config';
import ErrorModal from '../partials/ErrorModal';
import Loader from '../partials/loader';
import CameraAltIcon from '@mui/icons-material/CameraAlt';


interface FormData {
    waffleName: string,
    waffleDescription: string | null,
    waffleImageUrl: string,

}

interface imgData {
    requireSignedURLs: string,
    metadata: {
        title: string,
    }

}

const EnterWaffle: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();


    // === SET STATES =======
    const inputRef = useRef<HTMLInputElement>(null);
    const [waffleName, setWaffleName] = useState<string>('');
    const [waffleDescript, setWaffleDescript] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [file, setFile] = useState<any>('');
    const [imgURLParking, setImgURLParking] = useState<string>('');



    useEffect(() => {
        const { parkingImgURL } = location.state
        setImgURLParking(parkingImgURL)
    }, [location.state]);


    const handleCameraClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // handleCameraClick();

    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        const img = event.target.files?.[0];
        if (img) {
            setFile(img)
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWaffleName(event.target.value);
    };

    const handleDescriptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWaffleDescript(event.target.value);
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        // ====PArt 1 =====
        let formData1 = new FormData();
        formData1.append("file", file);

        await axios.post(imgURLParking, formData1)
            .then(async res => {

                // ====PArt 2 =====
                const formData: FormData = {
                    waffleName: waffleName,
                    waffleDescription: waffleDescript,
                    waffleImageUrl: res.data.result.variants[0],
                };
                await axios.post(config.apiUrl + 'waffles', formData, {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('captainWaffles')}`,
                    },
                })
                    .then(res => {
                        navigate('/');
                    }).catch(err => {
                        setError('Opps! Looks like that entry got burnt, please try uploading your entry again.')
                    });
            });


    };

    const closeModal = () => {
        setError(null);
    };

    const backToHome = () => {
        navigate(-1);
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <Typography variant="h5" sx={{ pt: 3, mb: 2 }}>
                    New Entry
                </Typography>

                <Button onClick={handleCameraClick} sx={{
                    color: "secondary.main"
                }}><CameraAltIcon></CameraAltIcon>Open Camera</Button>

                <div>
                    <Typography variant="subtitle1" sx={{ pt: 3, mb: 2 }}>
                        Selected Photo Preview:
                    </Typography>

                    {file && (
                        <img
                            src={URL.createObjectURL(file)}
                            alt="Selected"
                            width={200}
                        />
                    )}
                </div>

                <input
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleCapture}
                    style={{ display: 'none' }}
                    capture
                    required
                />

                <TextField
                    label="Entry Name"
                    value={waffleName}
                    onChange={handleNameChange}
                    fullWidth
                    margin="normal"
                    required
                />

                <TextField
                    margin="normal"
                    label="Entry description or comments"
                    value={waffleDescript}
                    onChange={handleDescriptChange}
                    multiline
                    rows={3}
                    fullWidth
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size='large'
                    sx={{ mt: 3, mb: 0 }}
                >
                    Upload Entry
                </Button>
                <Button
                    onClick={backToHome}
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    size='large'
                    sx={{ mt: 3, mb: 2, border: 2, }}
                >
                    Cancel
                </Button>

            </form>

            {error && <ErrorModal error={error} onClose={closeModal} />}
            {isLoading && <Loader loading={isLoading}></Loader>}

        </Box>
    );
};

export default EnterWaffle;
