import { Route, Routes } from "react-router-dom";
import { useMemo, useState } from "react";
import Login from '../pages/login';
import Timeline from '../pages/timeline';
import NotFound from '../pages/notFound';
import Rules from '../pages/rules';
import Scoreboard from '../pages/scoreboard';
import Profile from '../pages/profile';
import ViewWaffle from '../pages/viewWaffle';
import EnterWaffle from '../pages/enterWaffle';
import ProtectedRoutes from "../pages/protectedRoutes";
import { UserContext } from "../context/userContext";
import Camera from "../components/Camera";


interface User {
    huserid: number;
    token: string;
}

const Views = () => {
    const [user, setUser] = useState<User | null>(null);

    return (
        <UserContext.Provider value={{ user, setUser }}>

            <Routes>
                <Route path="/login" element={<Login />} />
                {/* Protected Routes */}
                <Route element={<ProtectedRoutes />}>
                    <Route path="/" element={<Timeline />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/scoreboard" element={<Scoreboard />} />
                    <Route path="/rules" element={<Rules />} />
                    <Route path="/viewWaffle/:id" element={<ViewWaffle />} />
                    <Route path="/camera" element={<Camera />} />
                    <Route path="/enterWaffle" element={<EnterWaffle />} />
                </Route>

                {/* Catch All */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </UserContext.Provider>
    );
};

export default Views;
