import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Skeleton } from '@mui/material';
import Cookies from 'js-cookie';
import useGet from "../hooks/useGet";


// interface User {
//     emailaddress: string,
//     fname: string,
//     huserid: number,
//     sname: string,
//     username: string,
//     userrole: string,
//     walletaddress: string,
// };
//10 * 60 * 1000); // 10 minutes


const Profile: React.FC = () => {

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { data } = useGet(`users/${Cookies.get('superWaffles')}`);

    useEffect(() => {
        if (data) {
            setName(`${data[0].fname} ${data[0].sname}`);
            setEmail(`${data[0].emailaddress}`);
            setIsLoading(false);
        }

    }, [data]);


    //SIGN OUT ================
    const navigate = useNavigate();
    const handleSignOut = () => {
        Cookies.remove('captainWaffles');
        Cookies.remove('superWaffles');
        navigate('/login');
    };

    return (
        <Box sx={{
            height: '100%',
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '1rem'
        }}>

            <Typography variant="h5" sx={{ pt: 3, mb: 2 }}>My Profile</Typography>

            {isLoading ? (
                <>
                    <Skeleton animation="wave" height={50} />
                    <Skeleton animation="wave" height={50} />
                </>) : (
                <>
                    <Typography variant="h6" >{name}</Typography>
                    <Typography variant="h6" >{email}</Typography>
                </>)}

            <Button
                onClick={handleSignOut}
                size="large"
                variant="contained"
                color="secondary"
                sx={{ mt: 3, mb: 2 }}
                fullWidth
            >Sign Out
            </Button>


        </Box>
    );
};

export default Profile;
