import { Navigate, Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';


const ProtectedRoutes = () => {
    const [loggedIn, setLoggedIn] = useState<boolean | null>(null);

    useEffect(() => {
        const checkLoggedIn = () => {
            const cookieValue = Cookies.get('captainWaffles');
            if (cookieValue) {
                setLoggedIn(true);
            } else {
                setLoggedIn(false);
            }
        };

        checkLoggedIn();
    }, []);

    return loggedIn === null ? null : loggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
