import { Typography, Box, TextField, Input } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import config from "../config";
import axios from "axios";
import {
  ArrowBackRounded,
  CancelOutlined,
  CheckCircleOutline,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
  SendOutlined,
} from "@mui/icons-material";
import "../style/index.css"; //for placeholder css
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ErrorModal from "../partials/ErrorModal";
import Loader from "../partials/loader";
import RatingCard from "../components/ratingCard";
import WaffleTimeline from "../components/waffleTimeline";

interface WaffleData {
  activeyn: string;
  datecreated: string;
  fname: string;
  huserid: number;
  hwaffleid: number;
  imagereference: string | null;
  sname: string;
  uservoteyn: number | null;
  votesno: number | null;
  votesyes: number | null;
  waffledescription: string | null;
  waffleimageurl: string;
  wafflename: string;
  wafflestatus: string;
}

interface Params {
  HwaffleID: number | undefined;
  voteYesYN: number;
  voteNoYN: number;
  voterComments: string;
}

interface CommentParameters {
  HwaffleID: number | undefined;
  history_comment: string;
}

interface CommentData {
  datecreated: string;
  fname: string;
  history_comment: string;
  huserid: number;
  hwaffleid: number | undefined;
  sname: string;
  voteyn: number | null;
}
// ========== Styling
const flex: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  marginTop: "1.2rem",
};

const imageContainer: React.CSSProperties = {
  // borderRadius: "30px 30px 0 0",
  width: "100%",
  height: "16rem",
  objectFit: "cover",
  overflow: "hidden",
};

const headerBar: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "1rem",
  paddingBottom: "0.5rem",
  backgroundColor: "transparent",
  position: "static",
};

const voteBar: React.CSSProperties = {
  background: "white",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  padding: "8px 10px",
  position: "relative",
  top: "-30px",
  right: "5%",
  marginBottom: "0",
  width: "auto" /* This will set the width based on content */,
};

const btnBar: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  marginTop: "20px",
  marginBottom: "8px",
};

const btn: React.CSSProperties = {
  padding: "10px 8px",
  display: "flex",
  justifyContent: "center",
  borderRadius: "12px",
  width: "-webkit-fill-available",
  color: "white",
};
const ViewWaffle = () => {
  const { id } = useParams<{ id: string }>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [waffleData, setwaffleData] = useState<WaffleData | undefined>();

  const [currVote, setCurrVote] = useState<string | null>(null);
  const [qualified, setQualified] = useState<boolean>(true);
  const [yesVotes, setYesVotes] = useState<number>(0);
  const [noVotes, setNoVotes] = useState<number>(0);
  const [showTimeline, setShowTimeline] = useState(true); //aka comments
  const [showRating, setShowRating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [keyboardOpen, setKeyboardOpen] = useState(false);

  let apiUrl1: string;
  apiUrl1 = config.apiUrl + `waffles/${id}`;
  useEffect(() => {
    axios
      .get(apiUrl1, {
        headers: {
          Authorization: `Bearer ${Cookies.get("captainWaffles")}`,
        },
      })
      .then((res) => {
        const waffle = res.data.data[0];
        waffle.waffle_rating =
          waffle.waffle_rating === null ? 0 : waffle.waffle_rating;
        waffle.waffle_rating_comment =
          waffle.waffle_rating_comment === null
            ? ""
            : waffle.waffle_rating_comment;
        setwaffleData(waffle);

        waffle.votesno ? setNoVotes(waffle.votesno) : setNoVotes(0);
        waffle.votesyes ? setYesVotes(waffle.votesyes) : setYesVotes(0);
        waffle.uservoteyn === 1 && setCurrVote("Yes");
        waffle.uservoteyn === 0 && setCurrVote("No");
        waffle.wafflestatus === "Rejected"
          ? setQualified(false)
          : setQualified(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl1]);

  useEffect(() => {
    const handleKeyboardShow = () => setKeyboardOpen(true);
    const handleKeyboardHide = () => setKeyboardOpen(false);

    window.addEventListener("keyboardDidShow", handleKeyboardShow);
    window.addEventListener("keyboardDidHide", handleKeyboardHide);

    return () => {
      window.removeEventListener("keyboardDidShow", handleKeyboardShow);
      window.removeEventListener("keyboardDidHide", handleKeyboardHide);
    };
  }, []); //keyboard event -> not in use

  const formatDate = (val: string) => {
    const January = "January";
    const February = "February";
    const March = "March";
    const April = "April";
    const May = "May";
    const June = "June";
    const July = "July";
    const August = "August";
    const September = "September";
    const October = "October";
    const November = "November";
    const December = "December";

    var monthNames = [
      January,
      February,
      March,
      April,
      May,
      June,
      July,
      August,
      September,
      October,
      November,
      December,
    ];

    var date = new Date(val);
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    return day + " " + monthNames[monthIndex] + " " + year;
  };

  const handleVoteClick = (
    vote: number,
    event: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setIsLoading(true);
    const params: Params = {
      HwaffleID: waffleData?.hwaffleid,
      voteYesYN: vote === 1 ? 1 : 0,
      voteNoYN: vote === 1 ? 0 : 1,
      voterComments: "",
    };

    const apiUrl = config.apiUrl + "votes";
    axios
      .post(apiUrl, params, {
        headers: {
          Authorization: `Bearer ${Cookies.get("captainWaffles")}`,
        },
      })
      .then((res) => {
        vote === 1 ? setCurrVote("Yes") : setCurrVote("No");
        const voteData = res.data.data;
        voteData.waffleStatus === "Rejected"
          ? setQualified(false)
          : setQualified(true);
        setNoVotes(voteData.voteNoYN || 0);
        setYesVotes(voteData.voteYesYN || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(`You can't vote on your own waffle. Dickhead.`);
        setIsLoading(false);
      });
  };

  const handleTimeLineClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setShowTimeline(true);
    setShowRating(false);
  };

  const handleRatingClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setShowTimeline(false);
    setShowRating(true);
  };

  const closeModal = () => {
    setError(null);
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box ref={containerRef} sx={{ height: "100vh", overflow: "auto" }}>
      <Box style={headerBar}>
        <ArrowBackRounded onClick={goBack}></ArrowBackRounded>
      </Box>

      {waffleData && (
        <img
          src={waffleData.waffleimageurl}
          style={imageContainer}
          alt="waffle"
        />
      )}

      <Box sx={{ marginRight: "0.7rem", marginLeft: "0.7rem" }}>
        <Box
          sx={{
            width: '100 %',
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box style={voteBar} sx={{ boxShadow: 2 }}>
            {currVote === "Yes" ? (
              <ThumbUp
                onClick={(event) =>
                  handleVoteClick(
                    1,
                    event as React.MouseEvent<SVGElement, MouseEvent>
                  )
                }
                sx={{
                  color: "primary.dark",
                  mr: 1,
                }}
              />
            ) : (
              <ThumbUpOutlined
                onClick={(event) =>
                  handleVoteClick(
                    1,
                    event as React.MouseEvent<SVGElement, MouseEvent>
                  )
                }
                sx={{
                  color: "primary.dark",
                  mr: 1,
                }}
              />
            )}
            <Typography
              variant="subtitle2"
              sx={{
                color: "primary.dark",
                mr: 1,
              }}
            >
              {yesVotes}
            </Typography>
            {currVote === "No" ? (
              <ThumbDown
                onClick={(event) =>
                  handleVoteClick(
                    0,
                    event as React.MouseEvent<SVGElement, MouseEvent>
                  )
                }
                sx={{
                  color: "secondary.main",
                  mr: 1,
                }}
              />
            ) : (
              <ThumbDownOutlined
                onClick={(event) =>
                  handleVoteClick(
                    0,
                    event as React.MouseEvent<SVGElement, MouseEvent>
                  )
                }
                sx={{
                  color: "secondary.main",
                  mr: 1,
                }}
              />
            )}
            <Typography
              variant="subtitle2"
              sx={{
                color: "secondary.main",
                mr: 2,
              }}
            >
              {noVotes}
            </Typography>
            {qualified ? (
              <>
                <CheckCircleOutline
                  sx={{
                    color: "success.light",
                  }}
                />{" "}
                <Typography variant="subtitle2" sx={{ ml: 1 }}></Typography>
              </>
            ) : (
              <>
                {" "}
                <CancelOutlined
                  sx={{
                    color: "secondary.dark",
                  }}
                />
                <Typography variant="subtitle2" sx={{ ml: 1 }}></Typography>
              </>
            )}
          </Box>
        </Box>


        <Box
          sx={{
            display: "flex",
            flexDirection: 'column',
            justifyContent: "space-between",
            color: "primary.light",
            marginTop: '-30px',
            marginBottom: '20px'
          }}
        >
          <Typography variant="body2">
            {waffleData?.fname} {waffleData?.sname}
          </Typography>
          <Typography variant="body2">
            {waffleData && formatDate(waffleData.datecreated)}
          </Typography>
        </Box>

        <Box sx={{ mt: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {waffleData?.wafflename}
          </Typography>
          <Typography variant="body2">{waffleData?.waffledescription}</Typography>
        </Box>

        <Box style={btnBar}>
          <Typography
            variant="button"
            style={btn}
            sx={{ backgroundColor: "primary.dark" }}
            // onClick={(event) => handleVoteClick(1, event as React.MouseEvent<SVGElement, MouseEvent>)}
            onClick={handleTimeLineClick}
          >
            <FavoriteBorderOutlinedIcon sx={{ color: "white", mr: 1 }} /> Timeline
          </Typography>

          <Typography
            variant="button"
            style={btn}
            sx={{ backgroundColor: "secondary.main" }}
            onClick={handleRatingClick}
          >
            <StarBorderOutlinedIcon sx={{ color: "white", mr: 1 }} /> Rating
          </Typography>
        </Box>

        {showTimeline && (
          <WaffleTimeline
            key={waffleData?.hwaffleid}
            waffleId={waffleData?.hwaffleid}
          />
        )}

        {showRating && <RatingCard key={waffleData?.hwaffleid} waffle={waffleData} />}

        {error && <ErrorModal error={error} onClose={closeModal} />}

        {isLoading && <Loader loading={isLoading}></Loader>}
      </Box>
    </Box>
  );
};

export default ViewWaffle;
