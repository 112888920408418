import Views from './pages/views';
import Navbar from './partials/navBar';
import { useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';

//Styles
import { Container, CssBaseline } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import Theme from "./style/theme";





const App: React.FC = () =>
{
  //  NAV BAR functions ==========
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const isEnterPage = location.pathname === "/enterWaffle";
  const isViewWafflePage=location.pathname.includes("/viewWaffle");
  const [navBarDisplay, setNavBarDisplay] = useState<boolean>(true);
  useEffect(() =>
  {
    // console.log(location);
    if (isLoginPage || isEnterPage||isViewWafflePage)
    {
      setNavBarDisplay(false);
    } else
    {
      setNavBarDisplay(true);
    }
  }, [isLoginPage, isEnterPage,isViewWafflePage]);

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Container style={{ backgroundColor: "#FFF", minHeight: "100vh", alignItems: 'center', minWidth: '100%' }}>

        {navBarDisplay && <Navbar />}
        <Views />



      </Container>
    </ThemeProvider>
  )
};

export default App;
