import { Typography, TextField, Box, Tabs, Tab, Button } from "@mui/material";
import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../config";
import axios from "axios";
import CommentCard from "../components/commentCard";
import { Padding, SendOutlined } from "@mui/icons-material";
import Cookies from "js-cookie";
import ErrorModal from "../partials/ErrorModal";
import LikesCard from "../components/likesCard";

const commentsContainer: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  justifyContent: "left",
  width: "100%",
  margin: "auto",
  marginBottom: "100px",
  marginTop: "20px",
  // maxHeight: "250px",
  // overflowY: "scroll",
};
const inputContainer: React.CSSProperties = {
  position: "fixed",
  width: "100%",
  bottom: 0,
  left: 0,
  minHeight: "70px",
  backgroundColor: "#006A6A",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  color: "#fff",
  gap: "4px",
  padding: "0px 4px",
};
const inputTextArea: React.CSSProperties = {
  width: "85%",
  color: "#fff",
  backgroundColor: "#006A6A",
  resize: "none",
  borderRadius: "8px",
  borderColor: "#FFF",
  padding: " 8px",
};

interface CommentData {
  datecreated: string;
  fname: string;
  history_comment: string;
  huserid: number;
  hwaffleid: number | undefined;
  sname: string;
  voteyn: number | null;
}
interface CommentParameters {
  HwaffleID: number | undefined;
  history_comment: string;
}

const WaffleTimeline = (obj: any) => {
  const formatDate = (val: string) => {
    const January = "Jan";
    const February = "Feb";
    const March = "Mar";
    const April = "Apr";
    const May = "May";
    const June = "Jun";
    const July = "Jul";
    const August = "Aug";
    const September = "Sep";
    const October = "Oct";
    const November = "Nov";
    const December = "Dec";

    var monthNames = [
      January,
      February,
      March,
      April,
      May,
      June,
      July,
      August,
      September,
      October,
      November,
      December,
    ];

    var date = new Date(val);
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    return day + " " + monthNames[monthIndex] + " " + year;
  };
  const formatTime = (val: string) => {
    // console.log("val", val);
    const date = new Date(val);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    //console.log("hours mins", `${hours}:${minutes}`);
    return `${hours}:${minutes}`;
  };
  const formatDateTime = (val: string) => {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    const isMatch = regex.test(val);
    return isMatch ? `${formatDate(val)}, ${formatTime(val)}` : val;
  };
  const calculateLikesArray = () => {
    let uniquePeepol = [];
    for (let i = 0; i < commentData.length; i++) {
      let obj = commentData[i];
    }
  };

  const { id } = useParams<{ id: string }>();
  const initialComments: CommentData[] = [];
  const [showTimeline, setShowTimeline] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [commentData, setCommentData] =
    useState<CommentData[]>(initialComments);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isSendVisible, setIsSendVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hwaffleif, setWaffleId] = useState<number>(0);

  const hwaffleid = obj.waffleId;

  //FETCH COMMENTS
  let apiUrl2: string;
  apiUrl2 = config.apiUrl + `waffles/historylog/${id}`;
  useEffect(() => {
    fetchHistorylog();
  }, [apiUrl2]);

  //POST COMMENT
  const handleCommentAdded = (
    event: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    const params: CommentParameters = {
      HwaffleID: hwaffleif, // waffleData?.hwaffleid,
      history_comment: inputValue,
    };

    const apiUrl = config.apiUrl + "waffles/recordcomment";
    axios
      .post(apiUrl, params, {
        headers: {
          Authorization: `Bearer ${Cookies.get("captainWaffles")}`,
        },
      })
      .then((res) => {
        //console.log(res);
        fetchHistorylog();
      })
      .catch((error) => {
        console.log("error", error);
        setError(`Comment failed to be added!`);
      });
    setInputValue("");
  };

  //POST CALL
  const fetchHistorylog = () => {
    axios
      .get(apiUrl2, {
        headers: {
          Authorization: `Bearer ${Cookies.get("captainWaffles")}`,
        },
      })
      .then((res) => {
        const comments = res.data.data;
        //console.log(comments);
        setCommentData([...comments].reverse()); //latest comment at bottom
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
    // calculateLikesArray();
  }, [commentData]);
  useEffect(() => {
    setWaffleId(hwaffleid);
  }, [hwaffleid]);
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
    event.target.value.length > 0
      ? setIsSendVisible(true)
      : setIsSendVisible(false);
  };
  const closeModal = () => {
    setError(null);
  };

  return (
    <Box >
      <Box style={commentsContainer}>


        {commentData.map((comment: CommentData, index: number) => {
          comment.datecreated = formatDateTime(comment.datecreated);
          if (comment.voteyn !== null) {
            return <LikesCard key={index} like={comment} />;
          } else if (comment.history_comment !== null) {
            return <CommentCard key={index} comment={comment} />;
          }
        })}

      </Box>

      <Box style={inputContainer}>
        {
          <textarea
            style={inputTextArea}
            className="commentBox"
            rows={2} // Initial number of rows
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Comment"
          />
        }
        {isSendVisible && (
          <SendOutlined
            onClick={(event) =>
              handleCommentAdded(
                event as React.MouseEvent<SVGElement, MouseEvent>
              )
            }
            sx={{ color: "#fff", mr: 1 }}
          />
        )}
      </Box>

      {error && <ErrorModal error={error} onClose={closeModal} />}
    </Box>
  );
};
export default WaffleTimeline;
