import { Typography, TextField, Box, Tabs, Tab, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import VoteBar from "./voteBar";


const card: React.CSSProperties = {
    position: 'relative',
    width: "100%",
    maxWidth: "400px",
    marginBottom: '2rem',
    borderRadius: "30px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",

};

const imageContainer: React.CSSProperties = {
    borderRadius: "30px 30px 0 0",
    width: '100%',
    height: '15rem',
    objectFit: 'cover',
    overflow: 'hidden',
};

const info: React.CSSProperties = {
    padding: '1rem 1.5rem',
    position: 'relative',

};

const voteBar: React.CSSProperties = {
    position: 'absolute',
    top: '-28px',
    right: '20px',
    padding: '0.5rem 1rem',
    borderRadius: 10,
    backgroundColor: 'white',


};

const WaffleCard = (waffle: any) => {
    const waff = waffle.waffle

    const navigate = useNavigate()
    const viewWaffle = () => {
        navigate(`/viewWaffle/${waff.hwaffleid}`);
    }

    return (

        <Box key={waff.huserid} style={card} onClick={viewWaffle}>
            <img src={waff.waffleimageurl} style={imageContainer} alt="waffle" />
            <Box style={info}>

                <Box style={voteBar} sx={{ boxShadow: 2 }}>
                    <VoteBar waffleID={waff.hwaffleid} />
                </Box>

                <Typography variant='subtitle2' >{waff.fname} {waff.sname}</Typography>
                <Typography variant='h6'>{waff.wafflename}</Typography>
            </Box>
        </Box>
    )

}

export default WaffleCard