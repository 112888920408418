import { createContext, Dispatch, SetStateAction } from "react";

interface User {
    huserid: number;
    token: string;
}

interface UserContextValue {
    user: User | null;
    setUser: Dispatch<SetStateAction<User | null>>;
}

export const UserContext = createContext<UserContextValue | undefined>(undefined);
