// config.ts

interface Config {
  apiUrl: string,
  accountID: string,
  APItokenCF: string,
  VAPIDKey: string,
}

const config: Config = {
  apiUrl:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL || ''
      : process.env.REACT_APP_API_URL_DEV || '',
  accountID: process.env.REACT_APP_ACCOUNT_ID_CF || '',
  APItokenCF: process.env.REACT_APP_API_TOKEN_CF || '',
  VAPIDKey: process.env.REACT_APP_VAPID_PUBLIC || '',
};

export default config;